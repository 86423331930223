// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopOnePodcastSeries_container__1ETSq {
  padding: 80px 64px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  color: #FEFEFE;
  background-color: #444444;
}

@media screen and (max-width: 900px) {
  .TopOnePodcastSeries_container__1ETSq {
    padding: 40px 32px;
    gap: 24px;
  }
}

@media screen and (max-width: 450px) {
  .TopOnePodcastSeries_container__1ETSq {
    padding: 40px 16px;
    gap: 16px;
  }
}

.TopOnePodcastSeries_title__syb3Y {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  max-width: 1312px;
  width: 100%;
}

.TopOnePodcastSeries_content__HKGz8 {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  max-width: 1312px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Podcasts/TopOnePodcastSeries.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE;IACE,kBAAkB;IAClB,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,SAAS;EACX;AACF;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".container {\n  padding: 80px 64px;\n  padding-bottom: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 32px;\n  color: #FEFEFE;\n  background-color: #444444;\n}\n\n@media screen and (max-width: 900px) {\n  .container {\n    padding: 40px 32px;\n    gap: 24px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .container {\n    padding: 40px 16px;\n    gap: 16px;\n  }\n}\n\n.title {\n  margin: 0;\n  padding: 0;\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 48px;\n  max-width: 1312px;\n  width: 100%;\n}\n\n.content {\n  margin: 0;\n  padding: 0;\n  font-weight: 300;\n  font-size: 24px;\n  line-height: 36px;\n  max-width: 1312px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TopOnePodcastSeries_container__1ETSq`,
	"title": `TopOnePodcastSeries_title__syb3Y`,
	"content": `TopOnePodcastSeries_content__HKGz8`
};
export default ___CSS_LOADER_EXPORT___;
