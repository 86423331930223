import { memo } from "react";
import styles from "./Feedback.module.css";
import styled from "styled-components";
import Slideshow from "../../addons/Slideshow";
import StyleSecondarySmallFalse2 from "../../components/StyleSecondarySmallFalse2";
import { useMediaQuery } from "@mui/material";

const feedback = Object.freeze([
  {
    name: "Roman Machuga",
    position: "Data Analyst | Power BI",
    role: "Mentee",
    feedback:
      "This program offers the opportunity to define your goals, focus on the most important things, and sometimes look at your development from a different perspective. It’s definitely worth recommending! ",
  },
  {
    name: "Renata Bratkowska",
    position: "Chief Data Officer",
    role: "Mentor",
    feedback:
      "Well-prepared in terms of support throughout the process, both substantively and in terms of tools. Meetings and matching participants provide great value to both parties. The organizers are open and positive, giving the impression that this mission is destined for success! 😊",
  },
  {
    name: "Tamara Karavaya",
    position: "Product Data Analyst",
    role: "Mentor",
    feedback:
      "My mentee turned out to be incredibly talented, with a genuine interest in development and a readiness to take on difficult challenges. Each of our meetings was not only an opportunity to share knowledge but also a source of inspiration for me. I am gratef ul to the Big Data Big Challenge program by SofixIt for this unique opportunity — creating a space where growth, support, and mutual motivation are born! ",
  },
  {
    name: "Natalia Traczewska",
    position: "Data Architect Intern",
    role: "Mentee",
    feedback:
      "Participating in the mentoring program was an amazing experience for me. I was paired with a fantastic mentor – it was truly a perfect match! Thanks to her support and guidance, I was able to land a new job while still in the program, and at the same time, significantly develop my skills and confidence. I highly recommend it to anyone looking to take the next step in their career.",
  },
  {
    name: "Magdalena Kasper",
    position: "Java Developer",
    role: "Mentee",
    feedback:
      'I had the pleasure of participating in the "Big Data – Data Engineer" mentoring program, and I am very pleased with this decision. Thanks to my mentor\'s support, I gained not only theoretical knowledge but, most importantly, practical skills that I could immediately apply in my work. The program was perfectly tailored to my needs, and the atmosphere was truly inspiring. I highly recommend it to anyone looking to grow in the field of Big Data – it’s truly a valuable investment in your career!',
  },
  {
    name: "Piotr Sobólski",
    position: "Expert at Digital Services Department",
    role: "Mentee",
    feedback:
      "A very interesting and well-prepared initiative in terms of content. In my case, I was perfectly matched with a Mentor who was fully engaged in the mentoring process. Throughout the entire duration of the program, I felt fully supported, both by my Mentor and by the individuals managing and supporting the program.",
  },
  {
    name: "Joanna Michalska",
    position: "Data Analysis",
    role: "Mentor",
    feedback:
      "Dzięki programowi mentoringowemu jako mentor uzyskasz więcej satysfakcji że sakjej pracy, natomiast jako mentee rozwiniesz skrzydła.",
  },
  {
    role: 'Mentee',
    feedback: 'The program helped me enhance my skills in programming and Big Data. Thanks to the agreement with my mentor, I had additional motivation to complete the previously agreed-upon courses, and the knowledge I gained there was applied to a project under my mentor\'s guidance. This was definitely a better learning experience for me than simply completing course tasks or blindly solving self-created problems.'
  },
  {
    name: 'Natalia Roślik',
    position: 'Data Management Manager', 
    role: 'Mentor',
    feedback: 'As a mentor in the Big Data - Big Challenges - Mentoring Program for Big Real Success, had the opportunity to support my mentee in their professional and personal development. It was an extraordinary experience that allowed me to share my knowledge and witness how, through our collaborative efforts, the mentee gained confidence, discovered new opportunities, and achieved their goals.'
  },
  {
    name: 'Paweł Zieliński',
    position: 'Head of Data & Analytics',
    role: 'Mentor',
    feedback: 'I had the pleasure of participating as a mentor in the first edition of the Mentoring Program for BIG REAL Success organized by Sofixit. The program turned out to be a fantastic opportunity to share knowledge, develop leadership skills, and support young talents in the world of Big Data.'
  },
  {
    role: 'Mentee',
    feedback: 'The "Big Data - Big Challenges - Mentoring Program for Big Real Success" is an excellent opportunity to gain new knowledge and skills. With the support of mentors and valuable training sessions, I was able to grow and establish valuable connections. I highly recommend it to anyone looking to advance in the field of Big Data!'
  },
]);

const Feedback = () => {
  const querySelector850 = useMediaQuery("(max-width: 850px)");

  return (
    <div className={styles.container}>
      {!querySelector850 && (
        <Slideshow
          GoBackComponent={ButtonBack}
          GoForwardComponent={ButtonForward}
          ControlsComponent={Controls}
          slideWidth={844}
          slideHeight={360}
          bounds={false}
          autoSlideTime={10}
        >
          {feedback.map((props, index) => (
            <FeedbackPiece key={index} {...props} />
          ))}
        </Slideshow>
      )}
      {querySelector850 &&
        feedback.map((props, index) => (
          <FeedbackPiece {...props} key={index} />
        ))}
    </div>
  );
};

export default memo(Feedback);

const FeedbackPiece = memo(({ name, position, role, feedback }) => {
  return (
    <div className={styles.feedbackContainer}>
      <div className={styles.feedbackHeadContainer}>
        {name && <h3 className={styles.feedbackSubtitle}>{name}</h3>}
        {position && <h3 className={styles.feedbackSubtitle}>{position}</h3>}
        {role && <h3 className={styles.feedbackRole}>{role}</h3>}
      </div>
      <h2 className={styles.feedbackContent}>{feedback}</h2>
    </div>
  );
});

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 60px));
  ${({ $orientation }) => ($orientation === "left" ? "left: 0" : "right: 0")};
  z-index: 2;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

const Controls = ({ onClick, childrenCount, pointer }) => {
  return (
    <ControlsWrapper>
      <div className={styles.sliderDots}>
        {Array(childrenCount)
          .fill(null)
          .map((_, index) => (
            <button
              key={index}
              className={styles.dotActive}
              onClick={() => onClick(index)}
            >
              <div className={index === pointer ? styles.dot : styles.dot1} />
            </button>
          ))}
      </div>
    </ControlsWrapper>
  );
};

const ButtonBack = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation="left">
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowleft16.svg"
        // iconRelume='/ArrowLeft.svg'
      />
    </ButtonWrapper>
  );
};

const ButtonForward = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation="right">
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowright161.svg"
        // iconRelume='/ArrowRight.svg'
      />
    </ButtonWrapper>
  );
};
