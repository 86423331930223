export const openMenteeForm = () => {
  // const link = 'https://docs.google.com/forms/d/1JxWyb77JX8UXyXiEke_BVuVTLTum9pmS_WyPJ4uqr3w/edit'
  const link = 'https://app.mentiway.com/sofixit/big-data-2025'
  window.open(link, '_blank')
}

export const openMentorForm = () => {
  // const link = 'https://docs.google.com/forms/d/114gDI1ygH0YITkoxJe0XBmWP2JR7sHOjZm3iNSqoLrY/edit'
  const link = 'https://app.mentiway.com/sofixit/big-data-2025'
  window.open(link, '_blank')
}
