import {memo, useCallback} from "react"
import styled from "styled-components"
import Jumpscare from "../../addons/Jumpscare"

const HeroContainer = styled.div`
  color: white;
  padding: 80px 64px 80px 64px;
  height: 554px;
  background-image: url(/happy-people.png);
  background-position: center;
  background-size: cover;
  background-color: #2A2F36CC;
  background-blend-mode: multiply;
  display: grid;
  place-items: center;
  @media (max-width: 510px) {
    padding: 0;
  }
`

const HeroContainerInner = styled.div`
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeroTopText = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  gap: 24px;
  @media (max-width: 850px) {
    font-size: 24px;
    gap: 16px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    gap: 12px;
    line-height: 100%;
    white-space: nowrap;
  }
`

const FatDot = styled.div`
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 100%;
`

const HeroMainText = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  text-align: center;
  @media (max-width: 850px) {
    font-size: 60px;
  }
  @media (max-width: 700px) {
    line-height: 100%;
    margin-top: 12px;
    font-size: 42px;
  }
  & > img {
    position: absolute;
    width: 400px;
    height: auto;
    top: -15px;
    left: 225px;
    @media (max-width: 850px) {
      width: 300px;
      top: 0;
      left: 170px;
    }
    @media (max-width: 700px) {
      width: 200px;
      top: -10px;
      left: 120px;
    }
  }
`

const HeroButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * + * {
    margin-top: 24px;
  }
`

const HeroButton = styled.button`
  width: 202px;
  height: 48px;
  border: 1px solid white;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.2s all ease;
  &:hover {
    background: rgba(0,0,0,0.8);
    scale: 1.02;
  }
  img {
    width: 24px;
    height: 24px;
  }
`

const Hero = () => {
  const handleSpotifyMain = useCallback(() => {
    const href = 'https://open.spotify.com/show/6nDDhuQettcx1NhZpSMW9H'
    window.open(href, '_blank')
  }, [])

  const handleYoutubeMain = useCallback(() => {
    const href = 'https://www.youtube.com/@Sofixitcompany'
    window.open(href, '_blank')
  }, [])

  return (
    <HeroContainer>
      <HeroContainerInner>
        <HeroTopText>
          <Jumpscare><p>BIG Data</p></Jumpscare>
          <Jumpscare delay={0.25}><FatDot /></Jumpscare>
          <Jumpscare delay={0.5}><p>Big Challenges</p></Jumpscare>
          <Jumpscare delay={0.75}><FatDot /></Jumpscare>
          <Jumpscare delay={1}><p>BIG Real Success</p></Jumpscare>
        </HeroTopText>
        <Jumpscare delay={1.5}>
          <HeroMainText>
            <img 
              src='/oval.svg'
              alt="Sofixit"
            />
            Sofixit Podcasts
          </HeroMainText>
        </Jumpscare>
        <HeroButtons>
          <Jumpscare delay={2}>
            <HeroButton onClick={handleSpotifyMain}>
              Listen on Spotify
              <img src="/spotify-icon.svg" alt="Spotify icon" />
            </HeroButton>
          </Jumpscare>
          <Jumpscare delay={2.25}>
            <HeroButton onClick={handleYoutubeMain}>
              Listen on YouTube
              <img src="/youtube-icon.svg" alt="YouTube icon" />
            </HeroButton>
          </Jumpscare>
        </HeroButtons>
      </HeroContainerInner>
    </HeroContainer>
  )
}

export default memo(Hero)
