import { memo } from "react";
import styles from "./Benefits.module.css";
import Jumpscare from "../../addons/Jumpscare";

const benefits = Object.freeze([
  {
    title: "Join a professional network",
    description:
      "Connect with experts in data analytics, AI, machine learning, and more! Build meaningful relationships and explore all things data: from engineering to management and cutting-edge platforms. </br> <b>2 joint online meetings for Mentees & Mentors on 15th of May 15 and on 15th of October</b>",
  },
  {
    title: "Comprehensive Training",
    description: "You will participate in a training program designed for Mentors and Mentees, which will prepare you for your role in the mentoring program and enhance the overall impact of the initiative. </br> <b>4 workshops for Mentors on 19th, 21th, 23th and 27th of May & 3 workshops for Mentee on 16th, 20th and 22th of May.</b>"
  },
  /*
  {
    title: "Comprehensive training",
    description:
      "You will participate in a training program designed for Mentors and Mentees, which will prepare you for your role in the mentoring program and enhance the overall impact of the initiative. </br> <b>6 hours for Mentors on 19 th, 21th, 23th and 27th of May & 4,5 hours for Mentee on 16th, 20th and 22th of May.</b>",
  },
  */
  {
    title: "Unique group intervision for Mentors",
    description:
      "As a Mentor, you will have the opportunity to participate in a group intervision session conducted by an accredited supervisor, offering a unique chance to exchange experiences with other Mentors. </br> <b>90 minuts on 5th of August.</b>",
  },
  {
    title: "Modern online platform",
    description:
      "You will gain access to a cutting-edge online platform to support you throughout the process. ",
  },
  {
    title: "Access to dedicated support",
    description:
      'You will have access to a "Hotline" for Mentors and Mentees, which you can use whenever needed during the mentoring program.',
  },
  {
    title: "Personalized mentoring sessions",
    description:
      "Opportunity to either lead or participate in six individual Mentor & Mentee sessions. ",
  },
  {
    title: "Possibilities to join to 3      networking events in Warsaw",
    description:
      "Reserve your  time for quality networking: </br> <b>on 29th of May 29, on 26th of June and on 24th of July 24.</b>",
  },
  {
    title: "5 months of development",
    description:
      "Long-term focus on your personal goal and development: </br> <b>from 15th of May to 15th of October.</b>",
  },
  {
    title: "9 thematic workshops",
    description:
      "A substantial dose of practical knowledge from the domain experts: </br> <b>on 3rd , 17th and 24th of June, on 8th and on  22nd of July, on 9th, 16th and 23rd of September, on 7th October</b>",
  },
]);

const Benefits = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Jumpscare variant="right">
          <h2 className={styles.title}>
            <span className={styles.highlight}>BIG</span> Data – BIG Challenges
            – Mentoring Program for Your{" "}
            <span className={styles.highlight}>BIG REAL</span> Success 
          </h2>
        </Jumpscare>
        <Jumpscare variant="left">
          <p className={styles.bottomText}>
            is a unique Mentoring Program on the market for professionals
            working in the area of DATA! The Mentoring Program includes all
            direction related with DATA.
          </p>
        </Jumpscare>
      </div>
      <Jumpscare variant="right">
        <h2 className={styles.title}>
          <span className={styles.highlight}>FREE</span> Mentoring Program for{" "}
          <span className={styles.highlight}>DATA</span> world!
        </h2>
      </Jumpscare>

      <div className={styles.benefitsContainer}>
        {benefits.map(({ title, description }, index) => (
          <div className={styles.benefitCard} key={index}>
            <Jumpscare stretch>
              <img src="/check-bold.png" alt="Check Bold" />
              <h3 className={styles.benefitCardTitle}>{title}</h3>
              <p
                className={styles.benefitCardDescription}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Jumpscare>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Benefits);
