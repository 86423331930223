import { memo, useMemo } from "react";
import styles from "./Certificates.module.css";
import styled from "styled-components";
import Slideshow from "../../addons/Slideshow";
import StyleSecondarySmallFalse2 from "../../components/StyleSecondarySmallFalse2";
import Jumpscare from "../../addons/Jumpscare";
import { useMediaQuery } from "@mui/material";

const certificates = [
  "/mentoring-cert-1.png",
  "/mentoring-cert-2.png",
  "/mentoring-cert-3.png",
];

const Certificates = () => {
  const mediaQuery1400 = useMediaQuery("(max-width: 1400px)");
  const mediaQuery900 = useMediaQuery("(max-width: 900px)");

  const slideWidth = useMemo(() => {
    switch (true) {
      case mediaQuery900:
        return 480;
      case mediaQuery1400:
        return 900;
      default:
        return 1312;
    }
  }, [mediaQuery1400, mediaQuery900]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Jumpscare>
          <h2 className={styles.title}>Certificate of Participation</h2>
        </Jumpscare>
        <Jumpscare>
          <p className={styles.subtitle}>
            Upon completing the program, you will receive a diploma confirming
            your participation as either a Mentee or a Mentor. 
          </p>
        </Jumpscare>
      </div>

      {!mediaQuery900 && (
        <Slideshow
          GoBackComponent={ButtonBack}
          GoForwardComponent={ButtonForward}
          ControlsComponent={Controls}
          slideWidth={slideWidth}
          slideHeight={566}
          bounds={false}
        >
          {certificates.map((cert, index) => (
            <div key={index} className={styles.certContainer}>
              <img src={cert} alt="Certificate" />
            </div>
          ))}
        </Slideshow>
      )}

    {mediaQuery900 && (
      <div className={styles.standaloneCertificates}>
        {certificates.map((cert, index) => (
          <img key={index}
            className={styles.standaloneCertImg}
            src={cert}
            alt='Certificate'
          />
        ))}
      </div>
      )}
    </div>
  );
};

export default memo(Certificates);

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 60px));
  ${({ $orientation }) => ($orientation === "left" ? "left: 0" : "right: 0")};
  z-index: 2;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

const Controls = ({ onClick, childrenCount, pointer }) => {
  return (
    <ControlsWrapper>
      <div className={styles.sliderDots}>
        {Array(childrenCount)
          .fill(null)
          .map((_, index) => (
            <button
              key={index}
              className={styles.dotActive}
              onClick={() => onClick(index)}
            >
              <div className={index === pointer ? styles.dot : styles.dot1} />
            </button>
          ))}
      </div>
    </ControlsWrapper>
  );
};

const ButtonBack = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation="left">
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowleft16.svg"
        // iconRelume='/ArrowLeft.svg'
      />
    </ButtonWrapper>
  );
};

const ButtonForward = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation="right">
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowright161.svg"
        // iconRelume='/ArrowRight.svg'
      />
    </ButtonWrapper>
  );
};
