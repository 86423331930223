import {memo} from "react"
import styles from './Subheader.module.css'
import Jumpscare from "../../addons/Jumpscare"
import {useMediaQuery} from "@mui/material"

const Subheader = () => {
  const mediaQuery500 = useMediaQuery('(max-width: 500px)')

  return mediaQuery500 ? (
    <div className={styles.container}>
      <Jumpscare variant='left'>
        <div className={styles.innerContainer}>
          <h2 className={styles.title}>
    Prepare for <br />
    <span className={styles.highlight}>2025 edition</span>
          </h2>
          <div className={styles.bottomContainer}>
            <h3 className={styles.subtitle}>
              Appy to be part of the Mentoring Program{' '}
              <b>Recruitment</b> from <b>March 13 until May 5</b>
            </h3>
          </div>
        </div>
      </Jumpscare>
    </div>
  ) : (
    <div className={styles.container}>
      <Jumpscare variant='left'>
        <div className={styles.innerContainer}>
          <h2 className={styles.title}>
            Prepare for <span className={styles.highlight}>2025 edition</span>
          </h2>
          <div className={styles.bottomContainer}>
            <h3 className={styles.subtitle}>
              Appy to be part of the Mentoring Program
            </h3>
            <h3 className={styles.subtitle}>
              <b>Recruitment</b> from <b>March 13 until May 5</b>
            </h3>
          </div>
        </div>
      </Jumpscare>
    </div>
  )
}

export default memo(Subheader)
