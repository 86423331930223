import {memo} from "react"
import styles from './FirstEdition.module.css'
import Jumpscare from "../../addons/Jumpscare"

const firstRow = Object.freeze([
  {
    title: '24',
    subtitle: 'Mentoring pairs',
  },
  {
    title: '1st',
    subtitle: 'Edition',
  },
  {
    title: '3',
    subtitle: 'Months of mentoring',
  }
])

const secondRow = Object.freeze([
  {
    picture: '/group-picture-1.png',
    title: '129',
    subtitle: 'Mentee & Mentor meetings so far, equaling 10,440 minutes',
  },
  {
    picture: '/group-picture-2.png',
    title: '450',
    subtitle: 'Minutes of workshops for Mentees and Mentors',
  },
  {
    picture: '/group-picture-3.png',
    title: '11,610',
    subtitle: 'Minutes of mentoring sessions',
  },
])

const thirdRow = Object.freeze([
  {
    title: '60%',
    subtitle: 'Of participants experienced their first Mentoring Program',
  },
  {
    title: '91,7%',
    subtitle: 'Satisfaction with the program',
  },
  {
    title: '87%',
    subtitle: 'Effectiveness in preparation for processes',
  },
])

const fourthRow = Object.freeze([
  '1 authentic and meaningful networking for Mentees & Mentors',
  '1 face-to-face meeting for Mentors with networking and celebration',
  '90 minutes intervision, led by Katarzyna Syrówka',
])

const FirstEdition = () => {
  return (
    <div className={styles.container}>
      <Jumpscare>
        <h1 className={styles.title}>
          The <span className={styles.highlight}>first edition</span> is already behind us!
        </h1>
      </Jumpscare>
      
      <Jumpscare>
        <img
          src='/beautiful-arrow-down.png'
          alt='Follow the Story'
        />
      </Jumpscare>

      <Jumpscare stretch>
        <div className={styles.whiteRowContainer}>
          {firstRow.map(({title, subtitle}, index) => (
            <div key={index} className={styles.whiteRowCell}>
              <h2 className={styles.whiteRowTitle}>{title}</h2>
              <h3 className={styles.whiteRowSubtitle}>{subtitle}</h3>
            </div>
          ))}
        </div>
      </Jumpscare>

      <Jumpscare stretch>
        <div className={styles.greyRowContainer}>
          {secondRow.map(({picture, title, subtitle}, index) => (
            <div key={index} className={styles.greyRowCell}>
              <div className={styles.pictureWithTitle}>
                <img
                  src={picture}
                  alt='First Edition Parameter'
                />
                <h2>{title}</h2>
              </div>
              <h3 className={styles.greyRowSubtitle}>
                {subtitle}
              </h3>
            </div>
          ))}
        </div>
      </Jumpscare>

      <Jumpscare stretch>
        <div className={styles.whiteRowContainer}>
          {thirdRow.map(({title, subtitle}, index) => (
            <div key={index} className={styles.whiteRowCell}>
              <h2 className={styles.whiteRowTitle}>{title}</h2>
              <h3 className={styles.whiteRowSubtitle}>{subtitle}</h3>
            </div>
          ))}
        </div>
      </Jumpscare>

      <Jumpscare>
        <div className={styles.greyRowContainer}>
          {fourthRow.map((title, index) => (
            <div key={index} className={styles.lastRowCell}>
              <img
                src='/check-bold.png'
                alt='Check'
              />
              <h2>{title}</h2>
            </div>
          ))}
        </div>
      </Jumpscare>
    </div>
  )
}

export default memo(FirstEdition)
