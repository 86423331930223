import styles from "./SoftwareDesignContainer.module.css";
import Jumpscare from "../addons/Jumpscare";

const SoftwareDesignContainer = () => {
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        {/*
        <div className={styles.content1}>
          <h1 className={styles.mediumLengthHero}>
            Software Design Done Right
          </h1>
        </div>
        <img className={styles.icon} alt="" src="/1@2x.png" />
        <img className={styles.icon1} alt="" src="/2.svg" />
        */}
        <div className={styles.content1}>
          <Jumpscare variant={'up'} transitionTime={0.75}>
            <h1 className={styles.mediumLengthHero}>
              <span className={styles.iconAnchor}>
                Software
                <img className={styles.icon} alt="" src="/1@2x.png" />
              </span>
              Design Done
              <span className={styles.iconAnchor}>
                Right
                <img className={styles.icon1} alt="" src="/2.svg" />
              </span>
            </h1>
          </Jumpscare>
        </div>
      </div>
      <div className={styles.column}>
        <Jumpscare delay={0.75}>
          <div className={styles.loremIpsumDolor}>
            { /* Dedicated to optimizing technology, prioritizing quality over
            quantity. */ }
            DataCloud Innovations: Bridging Big Data, Cloud-Native Transformation, and Custom Solutions
          </div>
        </Jumpscare>
        <div className={styles.column1}>
          <div className={styles.loremIpsumDolorContainer}>
            <ul className={styles.startups}>
              <Jumpscare delay={1.5}>
                <li>Startups</li>
              </Jumpscare>
            </ul>
          </div>
          <div className={styles.loremIpsumDolorContainer}>
            <ul className={styles.startups}>
              <Jumpscare delay={2}>
                <li>SME</li>
              </Jumpscare>
            </ul>
          </div>
          <div className={styles.loremIpsumDolorContainer}>
            <ul className={styles.startups}>
              <Jumpscare delay={2.5}>
                <li>Enterprise</li>
              </Jumpscare>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDesignContainer;
