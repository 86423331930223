import {memo} from "react"
import styles from './TopOnePodcastSeries.module.css'

const TopOnePodcastSeries = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        #1 podcast series
      </h2>
      <p className={styles.content}>
        In this series, the business asks questions, and industry experts provide answers on current topics in data management, sharing their experiences and insights.
      </p>
      <p className={styles.content}>
        The podcast series is produced by the boutique firm Sofixit, which offers a range of services and products in the field of data management. Every day, we support our clients in finding and implementing the best solutions for their needs and work towards the growth of the entire industry!
      </p>
      <p className={styles.content}>
        Some episodes are hosted by Olga Wisławnych, Head of Business Development at Sofixit, while others are led by AI, which has already become an integral part of our professional lives! Special thanks to Dominica Pogudz for the technical preparation and editing of the podcasts.
      </p>
    </div>
  )
}

export default memo(TopOnePodcastSeries)
