import { memo } from "react";
import styles from "./TechnologyPartner.module.css";
import Jumpscare from "../../addons/Jumpscare";

const TechnologyPartner = () => {
  return (
    <div className={styles.container}>
      <Jumpscare>
        <h2 className={styles.title}>Our partners</h2>
      </Jumpscare>
      <div className={styles.rowContainer}>
        <Jumpscare>
          <img className={styles.mentiway} src="/mentiway.png" alt="Mentiway" />
        </Jumpscare>
        <Jumpscare>
          <img
            className={styles.focusOn}
            src="/mentoring-tech-partner.png"
            alt="Focus On"
          />
        </Jumpscare>
        <Jumpscare>
          <img
            className={styles.damaPoland}
            src="/partner-dama-poland.jpg"
            alt="DAMA Poland"
          />
        </Jumpscare>
        <Jumpscare>
          <img
            className={styles.cdoForum}
            src="/mentoring-partner-cdo-forum.png"
            alt="CDO Forum"
          />
        </Jumpscare>
      </div>
    </div>
  );
};

export default memo(TechnologyPartner);
