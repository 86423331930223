import { memo, useCallback } from "react";
import styles from "./PodcastSeriesSoon.module.css";
import styled from "styled-components";

const HeroButton = styled.button`
  width: 202px;
  height: 48px;
  border: 1px solid white;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.2s all ease;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    scale: 1.02;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

const firstRow = Object.freeze([
  {
    title: '361',
    description: 'minutes of knowledge',
  },
  {
    title: '11',
    description: 'episodes of the podcast',
  },
  {
    title: '11',
    description: 'featuring Big Data specialists',
  },
])

const benefits = Object.freeze([
  {
    title: 'Legal Advisor and Expert in AI',
    description: 'Legal changes in AI Act and Data Act.',
  },
  {
    title: 'Data Scientist',
    description: 'Data quality and its impact on AI.',
  },
  {
    title: 'Senior Data Architect',
    description: 'Data architecture supporting organizations.',
  },
  {
    title: 'Data Science & AI Lead',
    description: 'Data labeling pipelines for machine learning.',
  },
  {
    title: 'Senior Data Technology Manager',
    description: 'Positioning data within organizations.',
  },
  {
    title: 'Technology Leader',
    description: 'Ethical and qualitative projects in data management.',
  },
  {
    title: 'Head of Data Science',
    description: 'Building data-driven organizations.',
  },
  {
    title: 'Product Design and Analytics',
    description: 'Big Data project implementation challenges.',
  },
  {
    title: 'BI Unit Manager',
    description: 'Data anonymization process challenges.',
  },
  {
    title: 'Data Engineering & Data Science Manager',
    description: 'Optimizing architecture efficiency.',
  },
  {
    title: 'Lead Architect',
    description: 'Goals and benefits of DAMA Chapter Poland.',
  },
  {
    title: 'BI Unit Manager',
    description: 'Data anonymization process challenges.',
  },
])

const PodcastSeriesSoon = () => {
  const handleOpenSpotify = useCallback(() => {
    const link = 'https://open.spotify.com/show/6nDDhuQettcx1NhZpSMW9H'
    window.open(link, '_blank')
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2 className={styles.headerContainerTitle}>
          2025 podcast series soon!
        </h2>
        <h3 className={styles.headerContainerSubtitle}>
          Big Data and Artificial Intelligence. How Data Quality Affects AI
          Power.
        </h3>
        <p className={styles.headerContainerText}>
          We are creating a new edition of our podcast series,
          exploring key topics related to Big Data, which are essential for the
          development of artificial intelligence (AI).  The goal of
          this project is to popularize knowledge about data and build a
          community of experts through discussions on crucial industry topics.
          We want to inspire specialists, both beginners and advanced, to
          develop their skills and take on new challenges in the data field,
          while supporting you in building personal brands in the market. 
        </p>
        <HeroButton onClick={handleOpenSpotify}>
          Listen on Spotify
          <img src="/spotify-icon.svg" alt="Spotify icon" />
        </HeroButton>
      </div>

      <div className={styles.firstRowContainer}>
        {firstRow.map(({ title, description }, index) => (
          <div key={index} className={styles.firstRowCell}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        ))}
      </div>

      <div className={styles.benefitsContainer}>
        {benefits.map(({ title, description }, index) => (
          <div key={index} className={styles.benefitCard}>
            <img
              src='/check-white.png'
              alt='Benefit'
            />
            <h2 className={styles.benefitCardTitle}>
              {title}
            </h2>
            <p className={styles.benefitCardDescription}>
              {description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(PodcastSeriesSoon);
