import { memo } from "react";
import Jumpscare from "../../addons/Jumpscare";
import styles from "./Header.module.css";
import { openMenteeForm, openMentorForm } from "../../addons/mentoring";

const Header = () => {
  return (
    <div className={styles.mentoring}>
      <Jumpscare stretch variant="left">
        <section className={styles.header1Web}>
          <div className={styles.column}>
            <div className={styles.columnInner}>
              <div className={styles.mediumLengthHeroHeadlineGoParent}>
                <Jumpscare delay={0.5}>
                  <h1 className={styles.mediumLengthHero}>{`BIG Data  `}</h1>
                </Jumpscare>
                <div className={styles.ellipseWrapper}>
                  <Jumpscare delay={0.75}>
                    <div className={styles.frameChild} />
                  </Jumpscare>
                </div>
                <Jumpscare delay={1}>
                  <h1
                    className={styles.mediumLengthHero1}
                  >{`BIG Challenges  `}</h1>
                </Jumpscare>
              </div>
            </div>
            <Jumpscare delay={1.5}>
              <div className={styles.secondColumn}>
                <div className={styles.circleParent}>
                  <div className={styles.circle}>
                    <img
                      className={styles.icon}
                      loading="lazy"
                      alt=""
                      /* src="/1@2x.png" */
                      src="/circle.svg"
                    />
                    <h1 className={styles.mediumLengthHero2}>{`Mentoring `}</h1>
                  </div>
                  <div className={styles.mediumLengthHeroHeadlineGoWrapper}>
                    <h1 className={styles.mediumLengthHero3}>Programm</h1>
                  </div>
                </div>
              </div>
            </Jumpscare>
            <div className={styles.thirdColumn}>
              <Jumpscare stretch delay={2}>
                <div className={styles.title}>
                  <div className={styles.mediumLengthHeroHeadlineGoContainer}>
                    <h1 className={styles.mediumLengthHero4}>for BIG REAL</h1>
                  </div>
                  <div className={styles.element}>
                    <img
                      className={styles.highlightYellowIcon}
                      loading="lazy"
                      alt=""
                      src="/highlight-yellow.svg"
                    />
                    <h1 className={styles.mediumLengthHero5}>Success</h1>
                  </div>
                </div>
              </Jumpscare>
              <div className={styles.container}>
                <div className={styles.actions}>
                  <div className={styles.ghostPlace}>
                    <div className={styles.button} onClick={openMenteeForm}>
                      <div className={styles.button1}>
                        <span>{`Sign Up as a `}</span>
                        <span className={styles.mentee}>Mentee</span>
                      </div>
                    </div>
                    <div className={styles.button2} onClick={openMentorForm}>
                      <div className={styles.button3}>
                        <span>{`I Want to be a `}</span>
                        <span className={styles.mentor}>
                          <b>Mentor</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <Jumpscare delay={2.75}>
                    <img
                      className={styles.joinNowIconClack}
                      alt=""
                      src="/join-now-icon-clack.svg"
                    />
                  </Jumpscare>
                </div>
                <div className={styles.actions1}>
                  <div className={styles.buttons}>
                    <Jumpscare delay={2.25}>
                      <button
                        className={styles.button4}
                        onClick={openMenteeForm}
                      >
                        <div className={styles.button5}>
                          <span
                            className={styles.signUpAs}
                          >{`Sign Up as a `}</span>
                          <span className={styles.mentee1}>Mentee</span>
                        </div>
                      </button>
                    </Jumpscare>
                    <Jumpscare delay={2.5}>
                      <button
                        className={styles.button6}
                        onClick={openMentorForm}
                      >
                        <div className={styles.button7}>
                          I Want to be a <b>Mentor</b>
                        </div>
                      </button>
                    </Jumpscare>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Jumpscare>
      <div className={styles.header1Mobile}>
        <div className={styles.column1}>
          <div className={styles.contentParent}>
            <div className={styles.content}>
              <div className={styles.frameParent}>
                <div className={styles.mediumLengthHeroHeadlineGoGroup}>
                  <Jumpscare>
                    <div className={styles.mediumLengthHero6}>{`BIG Data`}</div>
                  </Jumpscare>
                  <Jumpscare delay={0.25}>
                    <div className={styles.frameItem} />
                  </Jumpscare>
                </div>
                <div className={styles.mediumLengthHeroHeadlineGoFrame}>
                  <Jumpscare delay={0.5}>
                    <div
                      className={styles.mediumLengthHero7}
                    >{`BIG Challenges  `}</div>
                  </Jumpscare>
                </div>
              </div>
            </div>
            <Jumpscare delay={1}>
              <div className={styles.frameGroup}>
                <div className={styles.parent}>
                  <img className={styles.icon1} alt="" src="/circle.svg" />
                  <div className={styles.mediumLengthHero8}>{`Mentoring`}</div>
                </div>
                <div className={styles.mediumLengthHero9}>Programm</div>
              </div>
            </Jumpscare>
            <Jumpscare delay={1.5}>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <div className={styles.mediumLengthHero10}>for BIG REAL</div>
                </div>
                <div className={styles.vectorParent}>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    /* src="/vector.svg" */ src="/highlight-yellow.svg"
                  />
                  <div className={styles.mediumLengthHero11}>Success</div>
                </div>
              </div>
            </Jumpscare>
          </div>
          <div className={styles.actions2}>
            <Jumpscare delay={2}>
              <div className={styles.button8} onClick={openMenteeForm}>
                <div className={styles.button9}>
                  Sign Up as a <b>Mentee</b>
                </div>
              </div>
            </Jumpscare>
            <Jumpscare delay={2.25}>
              <div className={styles.button10} onClick={openMentorForm}>
                <div className={styles.button11}>
                  I Want to be a <b>Mentor</b>
                </div>
              </div>
            </Jumpscare>
            <Jumpscare delay={2.5}>
              <img
                className={styles.mobileVIcon}
                alt=""
                src="/mobile-v@2x.png"
              />
            </Jumpscare>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
