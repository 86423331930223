import React, {memo} from "react"
import Header from "./Header"
import Subheader from "./Subheader"
import Benefits from "./Benefits"
import Certificates from "./Certificates"
import Services from "../../components/Services"
import Layout from "../../components/Layout"
import About from "../../components/About"
import TechnologyPartner from "./TechnologyPartner"
import FirstEdition from "./FirstEdition"
import Feedback from "./Feedback"
import OurMentors from "./OurMentors"

const Mentoring = () => {
  return (
    <>
      <Header />
      <Subheader />
      <Benefits />
      <Certificates />
      <Services />
      <Layout />
      <About />
      <TechnologyPartner />
      <FirstEdition />
      <Feedback />
      <OurMentors />
    </>
  )
}

export default memo(Mentoring)
