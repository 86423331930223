import { memo, useCallback, useMemo, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Jumpscare from '../../addons/Jumpscare.js'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Podcast from '../../components/Podcast'
import Hero from './Hero.js'
import PodcastSeriesSoon from './PodcastSeriesSoon.js'
import TopOnePodcastSeries from './TopOnePodcastSeries.js'

const Root = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #1C1C1C;
`

const PodcastsContainer = styled.div`
  background-color: #444444;
  padding: 60px 64px 60px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ToggleButton = styled.div`
  border-radius: 4px;
  border: 1px solid #FEFEFE;
  background-color: rgba(0,0,0,0.4);
  padding: 12px 24px;
  display: flex;
  gap: 12px;
  color: #FEFEFE;
  cursor: pointer;
  width: 100%;
  max-width: 1312px;
  justify-content: center;
  
  opacity: 0.5;
  transition: 0.25s opacity ease-in-out;
  &:hover {
    opacity: 1;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  img {
    height: 24px;
    width: auto;
  }
`

const PodcastInfo = Object.freeze([
  {
    posterSrc: '/preface-poster.svg',
    title: 'Big Data and Artificial Intelligence. How Data Quality Affects AI Power.',
    guest: 'Guest: Magdalena Cebula | Data Scientist | Redge Technologies',
    description: 'Data Scientist at Redge Technologies focused on recommendation systems, and a research and teaching assistant at Warsaw University of Technology. Organizer of ML in PL conference and Polish ML Community meetups. Nominated for Top 100 Women in AI and DS.',
    youtubeLink: 'https://www.youtube.com/watch?v=UVgTZSZhJVA',
    spotifyLink: 'https://open.spotify.com/episode/7mSG31FDcTpOQ4O6DSJMS2',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/7mSG31FDcTpOQ4O6DSJMS2?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-2.svg',
    title: 'How Data Architecture supports the organization?',
    guest: 'Guest: Filip Dzięcioł | Senior Data Architect | Exerizon',
    description: 'Senior Data Architect at Exerizon and an academic instructor of MLOps at the Polish-Japanese Academy of Information Technology. Holds a bachelor\'s and master\'s degree with distinction, focusing on MLOps and Data Mesh, and is pursuing a Ph.D. in Data Governance and GenAI. Previously worked as a systems analyst, Mulesoft developer, and data integration consultant and architect.',
    youtubeLink: 'https://www.youtube.com/watch?v=R_UP5pFuZX0',
    spotifyLink: 'https://open.spotify.com/episode/4aZQAFKMCIk0z8pg1VfVaU',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4aZQAFKMCIk0z8pg1VfVaU?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-3.svg',
    title: 'Data labeling pipelines for Machine Learning.',
    guest: 'Guest: Marcin Bera | Data Science & AI Lead | BSH Home Appliances Group',
    description: "Graduate of the Warsaw School of Economics with over a decade of experience in data science, mainly in international corporations within home appliance manufacturing, telecommunications, and IT. Currently manages a Data & AI team and works as an AI Architect specializing in natural language processing at Europe's largest home appliance manufacturer.",
    youtubeLink: 'https://www.youtube.com/watch?v=c5HxwzMcjR0',
    spotifyLink: 'https://open.spotify.com/episode/2LyFUi0cylJrFU7ScxnUUM',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2LyFUi0cylJrFU7ScxnUUM?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-4.svg',
    title: 'Where should we currently position the Data area within organizations?',
    guest: 'Guest: Łukasz Wróblewski | Senior Data Technology Manager |Biedronka (Jeronimo Martins)',
    description: "Data Manager with over 10 years of experience in industries such as retail, digital, HR, and healthcare. In daily work, Łukasz manages the implementation of analytical projects and oversees the Data Technology team. Currently,  focused on building a data-driven organization across all areas of Biedronka's operations. He has a particular affinity for operational controlling and the optimization of store processes.",
    youtubeLink: 'https://www.youtube.com/watch?v=Gz_101MnUfU',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Gdzie-obecnie-powinnimy-pozycjonowa-obszar-Data-w-organizacjach-e2ou98a',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/07dzMDvq5U53e8uSrlXCfk?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-5.svg',
    title: 'Does all data need to be big? And is it possible to have qualitative and ethical projects in data management without a change in mindset?',
    guest: 'Guest: Wiktoria Gromowa - Cieślik | Technology Leader',
    description: 'Technology Leader with over 10 years of experience in IT and finance, progressing from analyst to director. She has led teams in large international banks, supported women in tech, and developed innovative solutions for process optimization, data models, system improvements, and data architectures. Currently focused on cloud-based solutions.',
    youtubeLink: 'https://www.youtube.com/watch?v=CsOJHX4GwkE',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Czy-kada-DATA-musi-by-BIG--I-czy-bez-zmiany-mindsetu-moliwe-s-jakociowe-i-etyczne-projekty-w-obszarze-zarzdzania-danymi-e2p7i2d',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2bvlOHysPrCApMcecHTWKI?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-6.svg',
    title: 'Building a data-driven organization as the key to improving data quality and the quality of artificial intelligence systems.',
    guest: 'Guest: Paweł Lubiński | Head of Data Science, |  GC Energy',
    description: 'A practitioner in the field of artificial intelligence (AI) development and digital transformation. Specializes in developing and implementing AI models. Has extensive experience in creating AI solutions that address business problems and contribute to the advancement of Industry 4.0 technologies. Co-founder of the Polish ML Community, where he continuously shares knowledge about building AI-based solutions.',
    youtubeLink: 'https://www.youtube.com/watch?v=6e4vOAdDNq8',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Tworzenie-organizacji-opartej-na-danych-jako-klucz-do-zwikszenia-jakoci-danych-oraz-jakoci-systemw-sztucznej-inteligencji-e2ph85p',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/3QqZwU295XuSIKQBSPq7BL?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-7.svg',
    title: 'Challenges and differences in Big Data project implementation: an analytical perspective.',
    guest: 'Guest: Paweł Matławski | Product Design and Analytics | Sofixit',
    description: 'Experienced specialist in business analytics and product design with over six years in the technology industry. Started as a Junior Business Analyst at Credit Suisse and advanced through roles at Unity Group and EFL Group. Currently a Product Owner at Sofixit, managing Big Data projects. Recently completed postgraduate studies in product design, combining technical knowledge with analytical and design expertise to lead innovative business solutions',
    youtubeLink: 'https://www.youtube.com/watch?v=5kChzCVSSlw',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Analiza-wyzwa-i-rnic-w-realizacji-projektw-Big-Data-z-perspektywy-analitycznej-e2pr5km',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/7eIuBJ0Qau7zJi8WzTFZyA?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-8.svg',
    title: 'The challenges of the data anonymization process.',
    guest: 'Guest: Marcin Myrta | BI Unit Manager | SofLab',
    description: 'BI leader and experienced data warehouse designer with skills in business and technical analysis. For nearly 7 years, he has been at Soflab Technology, leading the development of the Soflab G.A.L.L. data anonymization tool. He specializes in creating reporting systems for the telecommunications, financial, and public service sectors.',
    youtubeLink: 'https://www.youtube.com/watch?v=gh2oZcE6OI0',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Wyzwania-procesu-anonimizacji-danych-e2q4dpl',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2h2m72iymJDpOUpSk5Tv7z?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-9.svg',
    title: 'Increasing the efficiency of architecture. How to optimize work?',
    guest: 'Guest: Michał  Włodkowski  | Data Engineering & Data Science Manager | Eurocash',
    description: 'Experienced Analyst and Data Scientist from Warsaw, Manager of Modeling and Data Engineering at IPH (Eurocash Group). Specializes in transforming data into business recommendations. Skills include Pyspark, Python, R, and SQL. Extensive experience in the retail, consulting, pharmaceutical, and FMCG industries. Passionate about Data Science and involved in education and mentoring, helping organizations better utilize data. In personal time, interested in sports, reading books, and traveling.',
    youtubeLink: 'https://www.youtube.com/watch?v=GZbpsmksFEA',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Zwikszenie-efektywnoci-architektury--Jak-zoptymalizowa-prac--kiedy-model-raportowania-przestaje-dziaa-sprawnie---studium-przypadku-e2qcgf1',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/0HbQ01kc2rKUCrujesEYSQ?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: "/podcast-poster-9-5.png",
    title: "DAMA Chapter Poland. A group of data professionals.",
    guest: "Arkadiusz Dąbkowski | Lead Architect | The Stepstone Group",
    description: "Expert in data management and architecture, Arkadiusz Dąbkowski is the Lead Architect at The Stepstone Group and a board member of DAMA Chapter Poland. In this episode, we explore the goals of DAMA Chapter Poland, the history of DAMA International, the role of DAMA-DMBOK as the cornerstone of data management, the benefits of membership in DAMA Chapter Poland, and strategies for tackling the Certified Data Management Professional (CDMP) exam.",
    youtubeLink: "https://www.youtube.com/watch?v=Oy234vm11bU",
    spotifyLink: "https://creators.spotify.com/pod/show/sofixit/episodes/DAMA-Chapter-Poland--Grupa-profesjonalistw-dbajcych-o-wykorzystanie-najlepszych-praktyk-w-pracy-z-danymi-w-Polsce-e2r1anq",
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4Y5jyXo4G22AnlbU9Dr7Oa?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  },
  {
    posterSrc: '/podcast-poster-10-5.png',
    title: 'Artificial Intelligence through the eyes of a lawyer - how to prepare for legal changes?',
    guest: 'Guest: Przemysław Sotowski | Legal advisor and expert in AI',
    description: 'In this episode, we interview Przemysław Sotowski, a legal advisor and expert in artificial intelligence, about upcoming legal changes related to the AI Act and their impact on businesses and the public sector. We discuss challenges organizations face, key concepts such as risk classification and the roles of system providers, and practical steps to prepare for these changes. We also touch on the second act, the Data Act, and its implications for the data market. Thank you for joining us throughout this series, and don’t forget to explore our additional resources!',
    youtubeLink: 'https://www.youtube.com/watch?v=I1hhgSfcKUU',
    spotifyLink: 'https://creators.spotify.com/pod/show/sofixit/episodes/Sztuczna-inteligencja-oczami-prawnika---jak-si-przygotowa-na-zmiany-prawa-e2rkgut',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4o4DWqmJA7kYDUuXCiEt7K?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
])

const Podcasts = () => {
  const navigate = useNavigate()

  const handleClickLogo = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleClickLinkedin= useCallback(() => {
    const href = 'https://www.linkedin.com/company/sofixit/'
    window.open(href, '_blank')
  }, [])

  const [showAll, setShowAll] = useState(false)

  const handleToggle = useCallback(() => {
    setShowAll(prev => !prev)
  }, [])

  const visiblePodcasts = useMemo(() => {
    return showAll 
      ? PodcastInfo
      : PodcastInfo.slice(0, 2)
  }, [showAll])

  return (
    <Root>
      <Navbar
        frame11965='/frame-11965@2x.png'
        onLogoClick={handleClickLogo}
      />
      <Hero />
      <PodcastSeriesSoon />
      <TopOnePodcastSeries />

      <PodcastsContainer>
        {visiblePodcasts.map((info, index) => (
          <Jumpscare variant={index % 2 == 0 ? 'left' : 'right'}>
            <Podcast
              key={index}
              {...info}
            />
          </Jumpscare>
        ))}
        <ToggleButton onClick={handleToggle}>
          <p>{showAll ? 'Hide' : 'More episodes'}</p>
          <img
            style={{
              transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            src='/arrow-down-white.png'
            alt='Toggle Arrow'
          />
        </ToggleButton>
      </PodcastsContainer>

      <Footer
        frame11965="/frame-119651@2x.png"
        onLogoClick={handleClickLogo}
        onIconLinkedInClick={handleClickLinkedin}
      />
    </Root>
  )
}

export default memo(Podcasts)
