import {memo, useCallback, useMemo, useState} from "react"
import styles from './OurMentors.module.css'
import Jumpscare from "../../addons/Jumpscare"

const shortList = 6

const mentors = Object.freeze([
  {
    picture: '/mentor-picture-1.png',
    description: [
      'Alicja Kwasniewska, Ph.D.',
      'Senior Solutions',
      'Architect | GenAI',
      'Amazon Web Services',
    ],
  },
  {
    picture: '/mentor-picture-2.png',
    description: [
      'Wojciech Włodek',
      'Cloud Advisory Technical Manager', 
      'Cloud Advisory Architect',
      'PKO Bank Polski',
    ],
  },
  {
    picture: '/mentor-picture-3.png',
    description: [
      'Daria Ilina',
      'Senior Data Scientist',
      'Marketing Science Company',
    ],
  },
  {
    picture: '/mentor-picture-4.png',
    description: [
      'Łukasz Wróblewski',
      'Senior Manager / Head of Data',
      'Jeronimo Martins (Biedronka)',
    ],
  },
  {
    picture: '/mentor-picture-5.png',
    description: [
      'Katarzyna Z. Staroslawska',
      'Independent',
      'Innovation Expert',
    ],
  },
  {
    picture: '/mentor-picture-6.png',
    description: [
      'Milena Milewczyk',
      'Data Engineer',
      'EcoVadis',
    ],
  },
  {
    picture: '/mentor-picture-7.png',
    description: [
      'Renata Bratkowska',
      'Chief Data Officer',
      'Chief Analytics Officer',
      'Grupa NEUCA',
    ],
  },
  {
    picture: '/mentor-picture-8.png',
    description: [
      'Paweł Zieliński',
      'Head of Data & Analitycs',
      'Dunapack Packaging - Prinzhorn Group',
    ],
  },
  {
    picture: '/mentor-picture-9.png',
    description: [
      'Maria Bylina',
      'Data Governance Lead National',
      'Information Processing Institute',
    ],
  },
  {
    picture: '/mentor-picture-10.png',
    description: [
      'Paweł Lubiński',
      'Head of Data Science, AI Strategist',
      'GC ENERGY',
    ],
  },
  {
    picture: '/mentor-picture-11.png',
    description: [
      'Madalena Cebula',
      'Data Scientist',
      'Redge Technologies',
    ],
  },
  {
    picture: '/mentor-picture-12.png',
    description: [
      'Marta Janas',
      'Lead Data Science',
      'Equinix',
    ],
  },
  {
    picture: '/mentor-picture-13.png',
    description: [
      'Joanna Michalska',
      'TOP100 Women in Data Science',
      'Founder traffix',
      'Uniwersytet Jagielloński',
    ],
  },
  {
    picture: '/mentor-picture-14.png',
    description: [
      'Filip Dzięcioł',
      'Senior Data Architect',
      'Exerizon',
    ],
  },
  {
    picture: '/mentor-picture-15.png',
    description: [
      'Natalia Roślik',
      'Data Management',
      'Expert',
      'PwC Polska',
    ],
  },
  {
    picture: '/mentor-picture-16.png',
    description: [
      'Wiktoria Gromowa-Cieślik',
      'Technology Leader',
      'UBS',
    ],
  },
  {
    picture: '/mentor-picture-17.png',
    description: [
      'Kacper Wandel',
      'Data Engineer',
      'Kingfisher',
    ],
  },
  {
    picture: '/mentor-picture-18.png',
    description: [
      'Magdalena Bulak',
      'Head of Data & Product',
    ],
  },
  {
    picture: '/mentor-picture-19.png',
    description: [
      'Katarzyna Adamczewska',
      'Head of Business Intelligence and Data Science Solutions Department',
      'Group NEUCA',
    ],
  },
  {
    picture: '/mentor-picture-20.png',
    description: [
      'Radosław Brygała',
      'Data Engineering Tech Lead',
      'Lingaro',
    ],
  },
  {
    picture: '/mentor-picture-21.png',
    description: [
      'Janek Kaseja',
      'Director',
      'Go-to-Market',
      'adidas'
    ],
  },
  {
    picture: '/mentor-picture-22.png',
    description: [
      'Tamara Karavaya',
      'Digital Marketing Specialist,',
      'Product & Data Analyst',
    ],
  },
  {
    picture: '/mentor-picture-23.png',
    description: [
      'Konrad Kulig',
      'Group Product Manager',
      '(Analytics, BI, Big Data, Cloud)',
      'Tesco',
    ],
  },
  {
    picture: '/mentor-picture-24.png',
    description: [
      'Emil Podwysocki',
      'Head of the Laboratory of Databases and Business Analytics Systems National Information Processing Institute',
    ],
  },
])

const OurMentors = () => {
  const [showAll, setShowAll] = useState(false)

  const visibleMentors = useMemo(() => {
    return showAll
      ? mentors
      : mentors.slice(0, shortList)
  }, [showAll])

  const toggleShowAll = useCallback(() => {
    setShowAll(prev => !prev)
  }, [])

  return (
    <div className={styles.container}>
      <Jumpscare>
        <h2 className={styles.title}>
          Our Mentors in 2024
        </h2>
      </Jumpscare>

      <div className={styles.mentorsContainer}>
        {visibleMentors.map(({ picture, description }, index) => (
          <Jumpscare key={index}>
            <div className={styles.mentorCard}>
              <img
                src={picture}
                alt='Mentor'
              />
              {description.map((piece, index) => (
                <p key={index} className={styles.mentorDescription}>
                  {piece}
                </p>
              ))}
            </div>
          </Jumpscare>
        ))}
      </div>

      <div className={styles.toggleButton} onClick={toggleShowAll}>
        <p>{showAll ? 'Hide' : 'Show All'}</p>
        <img
          style={(showAll ? {
            transform: 'rotate(180deg)',
          } : {})}
          src='/arrow-down.png'
          alt='Expand'
        />
      </div>
    </div>
  )
}

export default memo(OurMentors)
