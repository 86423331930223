import {memo} from "react"
import styles from './TechnologyPartner.module.css'
import Jumpscare from "../../addons/Jumpscare"

const TechnologyPartner = () => {
  return (
    <div className={styles.container}>
      <Jumpscare>
        <h2 className={styles.title}>
          Technology partner
        </h2>
      </Jumpscare>
      <Jumpscare>
        <img
          className={styles.mentiway}
          src='/mentiway.png'
          alt='Mentiway'
        />
      </Jumpscare>
    </div>
  )
}

export default memo(TechnologyPartner)
