// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TechnologyPartner_container__Hshtl {
  background-color: #FEFEFE;
  padding: 80px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

@media screen and (max-width: 700px) {
  .TechnologyPartner_container__Hshtl {
    padding: 40px 32px;
  }
}

@media screen and (max-width: 500px) {
  .TechnologyPartner_container__Hshtl {
    padding-top: 0;
  }
}

.TechnologyPartner_title__QMST- {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #1C1C1C;
  margin: 0;
  padding: 0;
}

.TechnologyPartner_mentiway__eCKCU {
  height: 44px;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mentoring/TechnologyPartner.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".container {\n  background-color: #FEFEFE;\n  padding: 80px 64px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 32px;\n}\n\n@media screen and (max-width: 700px) {\n  .container {\n    padding: 40px 32px;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .container {\n    padding-top: 0;\n  }\n}\n\n.title {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 36px;\n  text-align: center;\n  color: #1C1C1C;\n  margin: 0;\n  padding: 0;\n}\n\n.mentiway {\n  height: 44px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TechnologyPartner_container__Hshtl`,
	"title": `TechnologyPartner_title__QMST-`,
	"mentiway": `TechnologyPartner_mentiway__eCKCU`
};
export default ___CSS_LOADER_EXPORT___;
