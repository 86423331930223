import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation, Navigate,
} from "react-router-dom";
import MainPage from "./pages/MainPage";
// import CaseStudiesWeb from "./pages/CaseStudiesWeb";
// import DesignTeamInSubscriptionWe from "./pages/DesignTeamInSubscriptionWe";
import CareerBusinessAnalystWeb from "./pages/CareerBusinessAnalystWeb";
import CareerGraphicDesignerWeb from "./pages/CareerGraphicDesignerWeb";
import CareerJuniorDevOpsEngineer from "./pages/CareerJuniorDevOpsEngineer";
import CareerSeniorMidDevOpsE from "./pages/CareerSeniorMidDevOpsE";
import CareerWeb from "./pages/CareerWeb";
import ServicesCloudMigrationEnable from "./pages/ServicesCloudMigrationEnable";
import ServicesIOutsourcingWeb from "./pages/ServicesIOutsourcingWeb";
import ServicesItConsultingWeb from "./pages/ServicesItConsultingWeb";
import ServicesItSoftwareDevelopme from "./pages/ServicesItSoftwareDevelopme";
import CompanyWeb from "./pages/CompanyWeb";
import ContactPopup, { PopupContextProvider } from "./addons/ContactPopup";
import BigData from "./pages/BigData";
import {AlertProvider} from "./addons/Alert";
import Mentoring from "./pages/Mentoring/index";
import Podcasts from "./pages/Podcasts/index";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "Sofixit: Big Data, Cloud, Custom software, Outsourcing services";
    let metaDescription = "Sofixit specializes in Big Data and Cloud services, offering tailored solutions that drive digital transformation. Leveraging advanced technologies like Apache Spark, Hadoop, and Kafka, we empower businesses to harness the full potential of their data for insightful decision-making and strategic growth.";

    switch (pathname) {
      case "/":
        title = "Sofixit: Big Data, Cloud, Custom software, Outsourcing services";
        metaDescription = "Sofixit specializes in Big Data and Cloud services, offering tailored solutions that drive digital transformation. Leveraging advanced technologies like Apache Spark, Hadoop, and Kafka, we empower businesses to harness the full potential of their data for insightful decision-making and strategic growth.";
        break;

      // case "/case-studies":
      //   title = "Case Studies";
      //   metaDescription = "Discover case studies of our company";
      //   break;
      //
      // case "/design-team":
      //   title = "Design Team in Subscription";
      //   metaDescription = "Hire a professional design team that will fulfill you needs";
      //   break;
      
      case "/mentoring":
        title = "Mentoring: for Big Real Success";
        metaDescription = 'Mentoring Program with expert mentors who are leaders in the industry ready to provide you with personalized guidance to ensure your success. Don’t miss this incredible opportunity to be part of the BIG Mentoring Program'
        break

      case "/career-business-analyst":
        title = "Career: Business Analyst";
        metaDescription = "Apply now!";
        break;

      case "/career-junior-devops-engineer":
        title = "Career: Junior Devops Engineer";
        metaDescription = "Apply now!";
        break;

      case "/career-junior-mid-graphic-designer":
        title = "Career: Junior/Mid Graphic Designer";
        metaDescription = "Apply now!";
        break;

      case "/career-senior-mid-devops":
        title = "Career: Senior/Mid Devops";
        metaDescription = "Apply now!";
        break;

      case "/career":
        title = "Current Openings";
        metaDescription = "Explore our current job openings and find the perfect fit for you.";
        break;

      case "/cloud-solutions":
        title = "Cloud Solutions";
        metaDescription = "Discover our cloud solutions";
        break;

      case "/outsourcing":
        title = "Outsource with Ease";
        metaDescription = "If you need to build or expand your project or product team, we can help you, whether you only need one expert or the entire team.";
        break;

      case "/consulting":
        title = "IT Consulting";
        metaDescription = "Leverage our expertise for strategic insights and optimized IT solutions.";
        break;

      case "/product-development":
        title = "Product Development";
        metaDescription = "Your business, our expertise in Product Development. Let's build together!";
        break;

      case "/our-company":
        title = "Our Company";
        metaDescription = "We are a Wroclaw based IT service company focused on technology optimization and consulting. With extensive technological and domain knowledge, we implement plans that follow tried and true practices from top consulting companies.";
        break;

      case "/big-data":
        title = "Big Data";
        metaDescription = "Use the power of Big Data solutions";
        break;

      case '/podcasts':
        title = 'Sofixit: Podcasts'
        metaDescription = 'Join us for the upcoming 2025 podcast series, where the business asks questions, and industry experts provide answers on current topics in data management, sharing their experiences and insights'
        break
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <PopupContextProvider>
      <AlertProvider>
        <ContactPopup/>
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          {/*<Route path="/case-studies" element={<CaseStudiesWeb/>}/>*/}
          {/*<Route*/}
          {/*  path="/design-team"*/}
          {/*  element={<DesignTeamInSubscriptionWe/>}*/}
          {/*/>*/}
          <Route 
            path="/mentoring"
            element={<Mentoring />}
          />
          <Route
            path="/career-business-analyst"
            element={<CareerBusinessAnalystWeb/>}
          />
          <Route
            path="/career-junior-devops-engineer"
            element={<CareerJuniorDevOpsEngineer/>}
          />
          <Route
            path="/career-junior-mid-graphic-designer"
            element={<CareerGraphicDesignerWeb/>}
          />
          <Route
            path="/career-senior-mid-devops"
            element={<CareerSeniorMidDevOpsE/>}
          />
          <Route path="/career" element={<CareerWeb/>}/>
          <Route
            path="/cloud-solutions"
            element={<ServicesCloudMigrationEnable/>}
          />
          <Route
            path="/outsourcing"
            element={<ServicesIOutsourcingWeb/>}
          />
          <Route
            path="/consulting"
            element={<ServicesItConsultingWeb/>}
          />
          <Route
            path="/product-development"
            element={<ServicesItSoftwareDevelopme/>}
          />
          <Route
            path="/our-company"
            element={<CompanyWeb/>}
          />
          <Route
            path="/big-data"
            element={<BigData/>}
          />
          <Route
            path='/podcasts'
            element={<Podcasts />}
          />
          <Route
            path="*"
            element={<Navigate to={"/"}/>}
          />
        </Routes>
      </AlertProvider>
    </PopupContextProvider>
  );
}
export default App;
